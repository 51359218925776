import React from "react";
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import ServicePage from "./pages/ServicePage";

const App = () => {

    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/service/:id" element={<ServicePage />} />
        </Routes>
      </Router>
    );

};

export default App;
