import EgoBalance from "../images/ego_balance.jpg";
import Hfc1 from "../images/hfc_1.jpg";
import Hfc2 from "../images/hfc_2.jpg";
import Kundalini from "../images/kundalini.jpg";
import HumanPotential from "../images/human_potential.jpg";

const services = [
  {
    title: "Session HFC-1 - High-Frequency Cleansing 1",
    description:
      "This energy healing session is designed for everyone, including seniors, children, and animals",
    fullDescription: `This energy healing session is designed for everyone, including seniors, children, and even
animals. It focuses on restoring the physical body’s functionality while also balancing and
harmonizing the energetic body by aligning all chakras.

We address key meridians associated with the lungs, large intestine, stomach, spleen, heart,
small intestine, bladder, kidneys, pericardium, gallbladder, and liver. Additionally, we
neutralize the effects of cultural influences, reinforcing the soul’s position within society.

The session also works with the astral body, releasing trapped emotions, instincts, and
hidden secondary benefits. For the mental body, we heal destructive and targeted programs,
promoting overall harmony and well-being.`,
    image: Hfc1,
  },
  {
    title: "Session HFC-2 - High-Frequency Cleansing 2",
    description:
      "This energy healing session is dedicated to addressing ancestral influences and karmic imprints that may be affecting your life.",
    fullDescription: `This energy healing session is dedicated to addressing ancestral influences and karmic
imprints that may be affecting your life. It involves a deep cleansing of harmful energies,
including remnants of black magic, dark or stagnant energy, and negative scenarios or
programs that no longer serve your highest good.

The session encompasses work on your present life, identifying and releasing patterns that
may be rooted in unresolved issues or inherited from previous generations. Additionally, it
extends to exploring and healing karmic connections from past reincarnations, helping to
free you from limiting cycles and fostering greater clarity, balance, and alignment with your
soul’s journey.

Please note that it is not allowed to do this session if you are pregnant!!!`,

    image: Hfc2,
  },
  {
    title: "Session Kundalini Activation",
    description:
      "Kundalini energy is a potent, grounding force often described as more material and earthly compared to other forms of spiritual energy.",
    fullDescription: `Kundalini energy is a potent, grounding force often described as more material and earthly
compared to other forms of spiritual energy. It is dense, primal, and deeply connected to our
physical existence. This energy is closely associated with creativity, sexuality, charisma, and
abundance, making it a fundamental source of vitality and personal power.

When awakened, Kundalini energy has the incredible ability to increase your overall energy
levels, fueling your body and mind with renewed strength and purpose. It stimulates and
brings to light your innate talents and abilities—those unique gifts you are meant to develop
and use in this lifetime. Additionally, it works to remove energetic blockages that may be
restricting your flow of abundance, including financial resources, allowing you to align with
prosperity and success.

The **Kundalini Activation Session** is designed for anyone seeking to transform their life on
a profound level. Whether you feel stuck, are looking to rediscover your passions, or wish to
embrace change and personal growth, this session offers a path to unlocking your full
potential. By awakening and channeling this powerful energy, you open yourself to a journey
of self-discovery, empowerment, and positive transformation, creating space for lasting
change and deeper fulfillment in all areas of your life.
Please note that it is not allowed to do this session if you are pregnant!!!`,
    image: Kundalini,
  },
  {
    title: "Session Transformation of EGO",
    description:
      "The EGO is an important part of who we are, acting like a mechanism that helps us navigate life.",
    fullDescription: `The EGO is an important part of who we are, acting like a mechanism that helps us navigate
life. It shapes our identity and how we interact with the world. However, the EGO needs to be
in balance to function properly. When it’s out of balance, it can negatively affect all areas of
life.

The problems arise when a person forgets their connection to their higher, spiritual self or
loses sight of the fact that they are part of a greater divine creation. Without this awareness,
the EGO can take over, draining the person’s energy and making them more easily
influenced by others. This can lead to manipulation, poor decisions, and, over time, self-
destructive behaviors.

The is designed to restore harmony between the EGO and your Higher Self. The session
helps to calm the overpowering influence of the EGO, bringing it back into balance so it can
support rather than control you. This allows you to reconnect with your true, spiritual self,
gain clarity, and make choices that align with your higher purpose.`,
    image: EgoBalance,
  },
  {
    title: "Discover Your True Potentials with the Human Potential System",
    description:
      "In a world where many feel lost, unsure of their purpose or career direction, the Human Potential System offers a guiding light.",
    fullDescription: `In a world where many feel lost, unsure of their purpose or career direction, the Human
Potential System offers a guiding light. From the moment we’re born, each of us is gifted
with unique talents—innate abilities that are meant to shape our lives and fulfill our mission
on Earth.
Yet, as we grow, these gifts often become buried under societal expectations, school
systems, and childhood experiences that condition us to conform. Over time, we lose touch
with our authentic selves, leaving us feeling unfulfilled and disconnected.
The Human Potential System is a powerful tool to uncover these hidden talents and realign
with your true purpose. By understanding your unique blueprint, you can break free from
limitations, rediscover your strengths, and set a course toward genuine self-realization.
Let me guide you on this transformative journey. Together, we’ll unlock the path that’s meant
for you, helping you reconnect with your inner gifts and step into the life you were always
meant to live.
**NOTE** This service involves a structured series of questions about your childhood, work,
hobbies, and other aspects of your life. It&#39;s essential to approach it with honesty and
openness, as some questions may touch on very personal topics. This work is very different
from my energy healing sessions it is based on factual information of your characteristics.`,
    image: HumanPotential,
  },
];

export default services;
