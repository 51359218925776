import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Navigation from "../compponents/Navigation";
//import Footer from "../compponents/Footer";
// import Navigation from "../Components/Navigation/Navigation";

const About = () => {
  return (
    <div>
      <Container>
       <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navigation />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "flex-start",
          padding: "20px",
          gap: 4,
        }}
      >
        <Box
          component="img"
          src={`${process.env.PUBLIC_URL}/valeriaphoto.jpg`}
          alt="Valeria"
          sx={{
            width: { xs: "100%", md: "40%" },
            height: "500px",
            borderRadius: "8px",
          }}
        />
        <Box>
          <Box sx={{ padding: "20px 20px", textAlign: "center" }}>
            <Typography variant="h3" gutterBottom>
              About Valeria
            </Typography>
          </Box>
          <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
            From an early age, I could sense energies and perceive things others
            couldn’t—a gift that filled me with both awe and fear. Over time,
            these abilities faded, but my deep connection to the mystical and an
            inner sense of purpose never left me. Throughout my journey, the
            universe offered subtle signs, gently nudging me back to my
            spiritual path whenever I strayed.
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
            In my quest to unlock my full potential, I discovered the Human
            Potential System taught by great masters. This transformative
            knowledge revealed how unique talents align with life’s higher
            purpose. As I delved deeper into the system and uncovered my true
            gifts, I realized that energy healing was my calling. With guidance
            and initiation from a master, I awakened this gift and embraced my
            role as a healer.
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
            Now, I am dedicated to helping others discover their talents,
            reconnect with their higher selves, and align with their true life
            path. Inspired by my own journey, I am honored to share the
            transformative healing energy that has profoundly shaped my life.
          </Typography>
        </Box>
      </Box>
    </Box>
    </Container>
     {/* <Footer /> */}
    </div>
  );
};

export default About;
