import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, Box, Button, Grid2 } from "@mui/material";
import Navigation from "../compponents/Navigation";

const ServicePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const service = location.state;

  const handleBookNow = () => {
    navigate("/contact");
  }

  if (!service) {
    return (
      <Container>
        <Typography variant="h4">Service not found</Typography>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Navigation />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "20px",
              alignItems: "stretch",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={service.image}
                alt={service.title}
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  height: "auto",
                  margin: "20px auto",
                  display: "block",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "60%" },
                padding: "20px",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                style={{ marginTop: "40px" }}
              >
                {service.title}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  marginTop: "20px",
                  marginBottom: "40px",
                  lineHeight: "1.6",
                  textAlign: "justify",
                }}
              >
                {service.fullDescription}
              </Typography>
              <Grid2 item xs={4} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: "5px"}}
                onClick={handleBookNow}
              >
                Book Now
              </Button>
              </Grid2>

            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ServicePage;
