import { Container, Button, Grid2, Typography } from "@mui/material"
import Navigation from "../compponents/Navigation"
import EnergyHealing from "../images/energy_healing.webp";



const HomePage = () => {
  return (
    <div>
      <Container>
        <Navigation />

        {/* Logo Section
        <Grid2
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <img
              src="/logolera.JPG"
              alt="LERAVYN Logo"
              style={{ height: "150px" }}
            />
          </div>
        </Grid2> */}

        {/* Main Section */}
        <Grid2
          container
          spacing={1}
          style={{ justifyContent: "center", marginTop: "60px", marginBottom: "60px" }}
        >
          {/* <Grid2 item xs={12}>
            <Typography variant="h3" align="center" gutterBottom>
              Your Journey To Wholeness Begins Here
            </Typography>
            <Typography variant="h6" align="center">
              Welcome to your journey of Kundalini, Energy Activation, and Yoga.
              Let's transform together.
            </Typography>
          </Grid2> */}

          {/* Energy Healing Image with Text */}
          <Grid2 item xs={12} style={{ position: "relative", marginTop: "20px" }}>
            <div
              style={{

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={EnergyHealing}
                alt="Energy Healing"
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                  filter: "brightness(70%)",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  textAlign: "center",
                  color: "#ffffff",
                  padding: "0 20px",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Transform Your Energy
                </Typography>
                <Typography variant="h6">
                  Explore the transformative power of Energy Healing and find
                  your inner balance.
                </Typography>
              </div>
            </div>
          </Grid2>

          {/* Recharge Program Section */}
          <Grid2 item xs={12} style={{ marginTop: "40px" }}>
            <Typography variant="h3" align="center" gutterBottom>
              Recharge
            </Typography>
            <Typography variant="h6" align="center">
              The Recharge program teaches ways to manage stress, create balance
              and harness the benefits of healthy lifestyle. Discover clarity
              of thought. Reconnect to yourself. Develop holistic routines to
              thrive.
            </Typography>
            <Grid2 container justifyContent="center" style={{ marginTop: "20px" }}>
              <Button variant="contained" color="inherit">
                Learn More
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </div>
  );
}

export default HomePage
