import React from 'react';
import { Grid2, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
  const location = useLocation();

  const linkStyle = {
    color: "#0d0101",
    textDecoration: "none",
    marginRight: "16px",
    fontWeight: "normal",
  };

  const activeLinkStyle = {
    ...linkStyle,
    textDecoration: "underline",
    fontWeight: "bold",
  };

  return (
    <Container>
      <Grid2 container alignItems="center" justifyContent="space-between" style={{ padding: "20px 0" }}>
      <Grid2 item xs={4}>
        <h1 style={{ fontFamily: "'Raleway', sans-serif", fontWeight: "bold", letterSpacing: "2px" }}>
          LERAVYN
        </h1>
      </Grid2>
      <Grid2 item xs={4} style={{ textAlign: "right" }}>
      <Link to="/" style={location.pathname === "/" ? activeLinkStyle : linkStyle}>
          Home
        </Link>
        <Link to="/about" style={location.pathname === "/about" ? activeLinkStyle : linkStyle}>
          About
        </Link>
        <Link to="/services" style={location.pathname === "/services" ? activeLinkStyle : linkStyle}>
          Services
        </Link>
        <Link to="/contact" style={location.pathname === "/contact" ? activeLinkStyle : linkStyle}>
          Contact
        </Link>
      </Grid2>
    </Grid2>
    </Container>

  );
}

export default Navigation;
