import React from "react";
import { Container, Button, Grid2, Typography } from "@mui/material";
import Navigation from "../compponents/Navigation";
import { useNavigate } from "react-router-dom";
import services from "../services/serviceData";
//import Footer from '../compponents/Footer';

const Services = () => {
  const navigate = useNavigate();

  const handleLearnMore = (service) => {
    navigate(`/service/${service.id}`, { state: service });
  };

  return (
    <div>
      <Container>
        <Navigation />
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          style={{ marginTop: "40px", fontFamily: "'Raleway', sans-serif" }}
        >
          Our Services
        </Typography>

        <Grid2
          container
          spacing={4}
          style={{ marginTop: "20px", marginBottom: "40px" }}
          justifyContent="center"
        >
          {services.map((service, index) => (
            <Grid2 item xs={12} sm={6} key={index}>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "20px",
                  borderRadius: "8px",
                  textAlign: "center",
                  width: "400px",
                  height: "650px",
                }}
              >
                <img
                  src={service.image}
                  alt={service.title}
                  style={{
                    width: "400px",
                    height: "400px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                    fontFamily: "'Raleway', sans-serif",
                  }}
                >
                  {service.title}
                </Typography>
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {service.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  onClick={() => handleLearnMore(service)}
                >
                  Learn More
                </Button>
              </div>
            </Grid2>
          ))}
        </Grid2>
      </Container>
      {/* <Footer /> */}
    </div>
  );
};
export default Services;
